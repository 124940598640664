import { State } from '@/store/modules/favorites/state'
import StorageService, { LocalStorageTypes } from '@/services/StorageService'
import { Cart } from '../cart/state'

/**
 * Mapping of mutation names to be used within components.
 * @example
 *
 * inside the mutation:
 * [MutationTypes.ADD_FAVORITES]: () => { return }
 *
 * inside the component:
 * store.commit(`${ModulesTypes.FAVORITES}/${MutationTypes.ADD_FAVORITES}`, payload)
 * Need to use module folder name when calling commit.
 * Use the ModulesTypes from '@/store'
 */
export enum MutationTypes {
  LOAD_FAVORITES = 'LOAD_FAVORITES',
  ADD_FAVORITES = 'ADD_FAVORITES',
  REMOVE_FAVORITES = 'REMOVE_FAVORITES',
  CLEAR_FAVORITES = 'CLEAR_FAVORITES',
}

/**
 * Mutations
 * Mapping of mutations
 */
export type Mutations<S = State> = {
  [MutationTypes.LOAD_FAVORITES](state: S): void
  [MutationTypes.ADD_FAVORITES](state: S, payload: any): void
  [MutationTypes.REMOVE_FAVORITES](state: S, payload: number): void
  [MutationTypes.CLEAR_FAVORITES](state: S): void
}

/**
 * Mutations
 * Define Mutations to Favorites Module
 */
const mutations: any = {
  /**
   * Mutation to load favorites from local storage
   * @param state: State
   */
  [MutationTypes.LOAD_FAVORITES](state: State) {
    const favorites = StorageService.get(LocalStorageTypes.FAVORITES)
    if (typeof favorites === 'string') {
      state.favorites = JSON.parse(favorites)
    }
    state.loading = false
  },
  /**
   * Mutation to add channel to favorites and update to local storage
   * @param state: State
   * @param payload: any
   */
  [MutationTypes.ADD_FAVORITES](state: State, payload: Cart) {
    state.favorites = [...state.favorites, payload]
    StorageService.add(
      LocalStorageTypes.FAVORITES,
      JSON.stringify(state.favorites)
    )
  },
  /**
   * Mutation to remove channel to favorites and update to local storage
   * @param state: State
   * @param payload: Number
   */
  [MutationTypes.REMOVE_FAVORITES](state: State, payload: number) {
    state.favorites.splice(payload, 1)
    StorageService.add(
      LocalStorageTypes.FAVORITES,
      JSON.stringify(state.favorites)
    )
  },
  /**
   * Mutation to clear all favorites
   * @param state: State
   */
  [MutationTypes.CLEAR_FAVORITES](state: State) {
    state.favorites = []
    StorageService.add(
      LocalStorageTypes.FAVORITES,
      JSON.stringify(state.favorites)
    )
  },
}

export default mutations
