const environment = ((hostName: string) => {
  const environments = {
    local: {
      apis: {
        shopping: {
          baseUri: 'https://localhost:7116/api',
          endpoints: {
            channelGroups: '/lottery/channels',
            products: '/lottery/products',
            consumerRegister: '/lottery/register',
            shoppingCart: '/lottery/cart',
          },
        },
      },
    },
    sb2: {
      apis: {
        shopping: {
          baseUri: 'https://shopping-portal-api-sb2.lab.lazlo.io/api',
          endpoints: {
            channelGroups: '/lottery/channels',
            products: '/lottery/products',
            consumerRegister: '/lottery/register',
            shoppingCart: '/lottery/cart',
          },
        },
      },
    },    
    dev: {
      apis: {
        shopping: {
          baseUri: 'https://shopping-portal-api-dev.lab.lazlo.io/api',
          endpoints: {
            channelGroups: '/lottery/channels',
            products: '/lottery/products',
            consumerRegister: '/lottery/register',
            shoppingCart: '/lottery/cart',
          },
        },
      },
    },
    qa: {
      apis: {
        shopping: {
          baseUri: 'https://portalapi-qa.shopping.lazlo.io/api',
          endpoints: {
            channelGroups: '/lottery/channels',
            products: '/lottery/products',
            consumerRegister: '/lottery/register',
            shoppingCart: '/lottery/cart',
          },
        },
      },
    },
    uat: {
      apis: {
        shopping: {
          baseUri: 'https://portalapi-uat.shopping.lazlo.io/api',
          endpoints: {
            channelGroups: '/lottery/channels',
            products: '/lottery/products',
            consumerRegister: '/lottery/register',
            shoppingCart: '/lottery/cart',
          },
        },
      },
    },
    prd: {
      apis: {
        shopping: {
          baseUri: 'https://shopping-portal-api.lazlo.io/api',
          endpoints: {
            channelGroups: '/lottery/channels',
            products: '/lottery/products',
            consumerRegister: '/lottery/register',
            shoppingCart: '/lottery/cart',
          },
        },
      },
    },    
  }
  const getApis = () => {
    switch (hostName) {
      case 'shopping-portal-sb2.lab.lazlo.io':
        return environments.sb2.apis
      case 'shopping-portal-dev.lab.lazlo.io':
        return environments.dev.apis        
      case 'portal-qa.shopping.lazlo.io':
        return environments.qa.apis
      case 'portal-uat.shopping.lazlo.io':
        return environments.uat.apis
      case 'shopping-portal-api.lazlo.io':
        return environments.prd.apis        
      case 'localhost':
        return environments.dev.apis
      default:
        return environments.dev.apis
    }
  }
  return {
    apis: getApis(),
  }
})(window.location.hostname)
export default environment
