/**
 * The Stack layout primitive injects
 * margin between elements via their
 * common parent.
 * @name 'stack-l'
 * @version 1.0.0
 */
import { defineComponent, h } from 'vue';
export default defineComponent({
    name: 'stack-l',
    setup(_props, { slots }) {
        return () => h('div', { class: ['stack-l'] }, slots);
    },
});
