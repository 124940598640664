import { ActionContext, ActionTree } from 'vuex'
import { Mutations, MutationTypes } from './mutations'
import { State } from './state'
import ChannelGroupsService from '@/services/ChannelGroupsService'
import ProductsService from '@/services/ProductsService'

/**
 * Mapping of actions names to be used within components.
 * @example
 *
 * inside the component:
 * store.dispatch(`${ModulesTypes.ECOMMERCE}/${ActionsTypes.GET_CHANNELS}`).then().catch()
 * Need to use module folder name when calling getter.
 * Use the ModulesTypes from '@/store'
 */
export enum ActionsTypes {
  GET_CHANNELS = 'getChannels',
}

type ActionAugments = Omit<ActionContext<State, State>, 'commit'> & {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>
}

/**
 * Actions
 * Mapping of actions
 */
export interface Actions {
  [ActionsTypes.GET_CHANNELS]({ commit }: ActionAugments, payload: any): void
}

/**
 * Actions
 * Define Actions to Channels Module
 */
export const actions: ActionTree<State, State> & Actions = {
  /**
   * Action to consume the API Channels Groups
   */
  [ActionsTypes.GET_CHANNELS]: async ({ commit }) => {
    commit(MutationTypes.SET_LOADING, false)
    const getChannelsGroup = await ChannelGroupsService.getAll().catch(() => {
      throw 'Error to load a channels'
    })
    const getProducts = await ProductsService.getAll().catch(() => {
      throw 'Error to load a products'
    })
    getChannelsGroup.data.data.forEach((group: { channels: any }) => {
      group.channels.forEach(
        (channel: { promotionRule: { promotionProducts: any[] } }) => {
          channel.promotionRule.promotionProducts.forEach((promotion) => {
            promotion.productDetail = getProducts.data.data.find(
              (product: { productId: any }) =>
                product.productId === promotion.productId
            )
            // TODO: This key is just for testing favorites and saving quickpick data and manual numbers. We'll need to remove or change it later.
            promotion.demoOptionsSelected = {
              isQuickPickSelected: false,
              amountId: '',
              amountCost: 0,
              amountOfGames: 1,
              selectedNumbers: [],
            }
          })
        }
      )
    })
    commit(MutationTypes.SET_LOADING, true)
    commit(MutationTypes.SET_CHANNELS, getChannelsGroup.data.data)
  },
}

export default actions
