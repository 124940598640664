import { Cart } from '../cart/state'

/**
 * State
 * Object mapping to state type
 */
export type State = {
  loading: Boolean
  favorites: Cart[]
}

/**
 * State
 * Set State to Channels Module
 */
const state: State = {
  loading: true,
  favorites: [],
}

export default state
