import axios, { AxiosInstance } from 'axios'
import environment from './helpers/filters/environments'

/**
 * Api Client
 * @description: Define a new instance of axios with a custom config
 */
const apiClient: AxiosInstance = axios.create({
  baseURL: environment.apis.shopping.baseUri,
  headers: {
    'Content-type': 'application/json',
  },
})
export default apiClient
