/**
 * A simple separator.
 * @name 'row-l'
 * @version 1.0.0
 */
import { defineComponent, h } from 'vue';
export default defineComponent({
    name: 'row-l',
    setup(_props, { slots }) {
        return () => h('div', { class: ['row-l'] }, slots);
    },
});
