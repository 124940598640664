/**
 * State
 * Object mapping to state type
 */
export type State = {
  loading: Boolean
  channels: Array<any>
}

/**
 * State
 * Set State to Channels Module
 */
const state: State = {
  loading: false,
  channels: [],
}

export default state
