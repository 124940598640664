import { MutationTree } from 'vuex'

import { GameState } from './state'

export enum MutationTypes {
  NEW_GAME = 'NEW_GAME',
  ADD_PRODUCT_IN_GAME = 'ADD_PRODUCT_IN_GAME',
  ADD_COMPONENT_SELECTION = 'ADD_COMPONENT_SELECTION',
  REMOVE_ALL_BALS = 'REMOVE_ALL_BALLS',
  UPDATE_PRODUCT_QUANTITY = 'UPDATE_PRODUCT_QUANTITY',
  UPDATE_GAME_VALIDATION = 'UPDATE_GAME_VALIDATION',
}

export type NewGameOptions = {
  channelId: string
}

export type ComponentSelectionOptions = {
  id: string
  optionId: string
  productId: string
  ball?: boolean
  ticket: number
}

export type ProductQuantityUpdate = {
  productId: string
  quantity: number
}

export type GameValidationUpdate = {
  productId: string
  validation: boolean
}

type RemoveAllBalls = {
  productId: string
  quickPick: boolean
}

const mutations: MutationTree<GameState> = {
  [MutationTypes.NEW_GAME](state, channelId: string) {
    state.currentGame = {
      purchaseSelectionItems: [],
      channelId,
    }
    state.gamesValidation = {}
  },
  [MutationTypes.ADD_PRODUCT_IN_GAME](state, productId: string) {
    state.currentGame.purchaseSelectionItems = [
      ...state.currentGame.purchaseSelectionItems,
      { componentSelections: [], promotionProductId: productId, ticket: 1 },
    ]
    state.gamesValidation[productId] = false
  },
  [MutationTypes.ADD_COMPONENT_SELECTION](
    state,
    componentSelection: ComponentSelectionOptions
  ) {
    const validationAddedComponent = (ticket: number) => {
      if (!componentSelection.ball) {
        return true
      }
      return ticket === componentSelection.ticket + 1
    }

    state.currentGame.purchaseSelectionItems.forEach((product) => {
      if (
        product.promotionProductId === componentSelection.productId &&
        validationAddedComponent(product.ticket)
      ) {
        const componentExists = product.componentSelections.findIndex(
          (component) => component.componentId === componentSelection.id
        )
        if (componentExists >= 0) {
          product.componentSelections[componentExists] = {
            ...product.componentSelections[componentExists],
            optionId: componentSelection.optionId,
            ball: componentSelection.ball ?? false,
          }
        } else {
          product.componentSelections = [
            ...product.componentSelections,
            {
              componentId: componentSelection.id,
              extensions: {},
              optionId: componentSelection.optionId,
              ball: componentSelection.ball ?? false,
            },
          ]
        }
      }
    })
  },
  [MutationTypes.REMOVE_ALL_BALS](state, removeAllBalls: RemoveAllBalls) {
    if (!removeAllBalls.quickPick) {
      state.gamesValidation[removeAllBalls.productId] = false
    } else {
      state.currentGame.purchaseSelectionItems.forEach((product) => {
        if (product.promotionProductId === removeAllBalls.productId) {
          product.componentSelections = product.componentSelections.filter(
            (component) => !component.ball
          )
        }
      })
      state.gamesValidation[removeAllBalls.productId] = true
    }
  },
  [MutationTypes.UPDATE_PRODUCT_QUANTITY](
    state,
    updateQuantity: ProductQuantityUpdate
  ) {
    const { productId, quantity } = updateQuantity
    const currentQuantity = state.currentGame.purchaseSelectionItems.filter(
      (product) => product.promotionProductId === productId
    ).length

    if (currentQuantity < quantity) {
      const purchaseSelection = state.currentGame.purchaseSelectionItems.map(
        (product) => ({
          ...product,
          componentSelections: product.componentSelections.filter(
            (component) => !component.ball
          ),
        })
      )
      const productClone = purchaseSelection.find(
        (product) => product.promotionProductId === productId
      )
      if (productClone) {
        state.currentGame.purchaseSelectionItems = [
          ...state.currentGame.purchaseSelectionItems,
          ...Array.from(
            { length: quantity - currentQuantity },
            (_prop, index: number) => ({
              ...productClone,
              ticket: index + currentQuantity + 1,
            })
          ).flat(),
        ]
      }
    }
  },
  [MutationTypes.UPDATE_GAME_VALIDATION](
    state,
    gameValidationUpdate: GameValidationUpdate
  ) {
    state.gamesValidation[gameValidationUpdate.productId] =
      gameValidationUpdate.validation
  },
}

export default mutations
