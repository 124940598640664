import { Cart, CartState } from './state'
import { ActionTree } from 'vuex'
import CartService from '@/services/CartService'

export enum ActionsTypes {
  SUBMIT_CART = 'SUBMIT_CART',
}

const actions: ActionTree<CartState, CartState> = {
  [ActionsTypes.SUBMIT_CART]: async ({ state }) => {
    const cart: Cart[] = state.cart
    const payload = cart.map((cart) => cart.games).map((game) => game[0])
    return await CartService.submitCartAndGenerateCode(payload)
  },
}

export default actions
