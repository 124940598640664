import { MutationTree } from 'vuex'
import StorageService, { LocalStorageTypes } from '@/services/StorageService'
import { Cart, CartState } from './state'

export enum MutationTypes {
  LOAD_CART = 'LOAD_CART',
  ADD_TO_CART = 'ADD_TO_CART',
  REMOVE_FROM_CART = 'REMOVE_FROM_CART',
  CLEAR_CART = 'CLEAR_CART',
}

const mutations: MutationTree<CartState> = {
  [MutationTypes.ADD_TO_CART](state, itemCart: Cart) {
    state.cart = [...state.cart, itemCart]
    StorageService.add(LocalStorageTypes.CART, JSON.stringify(state.cart))
  },
  [MutationTypes.REMOVE_FROM_CART](state, index: number) {
    state.cart.splice(index, 1)
    StorageService.add(LocalStorageTypes.CART, JSON.stringify(state.cart))
  },
  [MutationTypes.CLEAR_CART](state) {
    state.cart = []
    StorageService.add(LocalStorageTypes.CART, JSON.stringify(state.cart))
  },
  [MutationTypes.LOAD_CART](state) {
    const cart = StorageService.get(LocalStorageTypes.CART)
    if (typeof cart === 'string') {
      state.cart = JSON.parse(cart)
    }
  },
}

export default mutations
