import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-4101c72d"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "panel" };
const _hoisted_2 = { class: "modal" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_lz_icon = _resolveComponent("lz-icon");
    const _component_cluster_l = _resolveComponent("cluster-l");
    const _component_row_l = _resolveComponent("row-l");
    const _component_stack_l = _resolveComponent("stack-l");
    const _component_box_l = _resolveComponent("box-l");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_box_l, null, {
                default: _withCtx(() => [
                    _createVNode(_component_stack_l, null, {
                        default: _withCtx(() => [
                            (_ctx.$slots.header)
                                ? (_openBlock(), _createBlock(_component_row_l, { key: 0 }, {
                                    default: _withCtx(() => [
                                        _createVNode(_component_cluster_l, { class: "justify:between" }, {
                                            default: _withCtx(() => [
                                                _renderSlot(_ctx.$slots, "header", {}, undefined, true),
                                                ($props.hasClose)
                                                    ? (_openBlock(), _createBlock(_component_lz_icon, {
                                                        key: 0,
                                                        class: "close",
                                                        onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.$emit('close'))),
                                                        name: "close"
                                                    }))
                                                    : _createCommentVNode("", true)
                                            ]),
                                            _: 3
                                        })
                                    ]),
                                    _: 3
                                }))
                                : _createCommentVNode("", true),
                            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
                        ]),
                        _: 3
                    })
                ]),
                _: 3
            })
        ])
    ]));
}
