import { createStore } from 'vuex'
import Channels from '@/store/modules/channels'
import Game from '@/store/modules/game'
import Favorites from '@/store/modules/favorites'
import Cart from '@/store/modules/cart'

/**
 * Mapping of module names to be used in components
 * when using Actions, mMutations and Getters
 * @example
 *
 * Call mutation function
 * store.commit(`${ModulesTypes.ECOMMERCE}/${MutationTypes.TOGGLE_DRAWER}`, payload)

 * Call Getters function
 * store.getters[`${ModulesTypes.ECOMMERCE}/${GettersTypes.COUNT_GAMES_CART}`]
 */
export enum ModulesTypes {
  CHANNELS = 'Channels',
  GAME = 'Game',
  FAVORITES = 'Favorites',
  CART = 'Cart',
}

/**
 * Create a new store instance to application
 */
export const store = createStore<any>({
  modules: {
    Game,
    Cart,
    Channels,
    Favorites,
  },
  state: {},
  actions: {},
  mutations: {},
  getters: {},
})

/**
 * Declare to store instance
 */
export function useStore() {
  return store
}

export default store
