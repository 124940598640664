import state from './state'
import mutations from './mutations'
import actions from './actions'

export const Cart = {
  namespaced: true,
  state,
  mutations,
  actions,
}

export default Cart
