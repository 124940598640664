export type ComponentSelection = {
  componentId: string
  optionId: string
  extensions: object
  ball: boolean
}

export type PurchaseSelectionItem = {
  componentSelections: ComponentSelection[]
  promotionProductId: string
  ticket: number
}

/**
 * Game
 * Object mapping to game type
 */
export type Game = {
  channelId: string
  purchaseSelectionItems: PurchaseSelectionItem[]
}

export type GameValidations = {
  [key: string]: boolean
}

/**
 * State
 * Object mapping to state type
 */
export type GameState = {
  currentGame: Game
  gamesValidation: GameValidations
}

/**
 * State
 * Set State to Game Module
 */
const state: GameState = {
  currentGame: {
    channelId: '',
    purchaseSelectionItems: [],
  },
  gamesValidation: {},
}

export default state
