import http from '@/http-common'
import { AxiosResponse } from 'axios'
import StorageService, { LocalStorageTypes } from '@/services/StorageService'
import environment from '@/helpers/filters/environments'

export type PayloadConsumerRegistration = {
  data: {
    CountryCode: string
    Handle: string
    LanguageCode: string
    SelfieBase64: string
    Data?: Object | null
    Tags: []
  }
}

export type ResponseConsumerRegistration = {
  correlationId: string
  simulationTypes: bigint
  location?: string
  helpUri?: string
  error?: string
  data: {
    deviceId: string
    consumerLicenseCode: string
    avatarUrl?: string
  }
  dataLabel: string
  properties: {}
}

/**
 * Consumer Service
 * @description: Service for /consumer APIs
 * @version: 1.0.0
 */
class ConsumerService {
  /**
   * Route Consumer Registration
   * Service to register the user and generate a token to consume the checkout and cart APIs
   */
  registration(payload: PayloadConsumerRegistration): Promise<any> {
    const headers = {
      'lazlo-location': '36.366667,-94.213333', //@TODO: The lazlo-location key will be removed from the request
    }
    return http
      .post(environment.apis.shopping.endpoints.consumerRegister, payload, {
        headers,
      })
      .then((response: AxiosResponse<ResponseConsumerRegistration>) => {
        StorageService.add(
          LocalStorageTypes.CONSUMER_LICENSE_CODE,
          response.data.data.consumerLicenseCode
        )
        return response.data
      })
      .catch((error: any) => {
        return error
      })
  }
}

export default new ConsumerService()
