/**
 * Mapping of getter names to be used within components.
 * @example
 *
 * inside the getter:
 * [GettersTypes.ANY]: () => { return }
 *
 * inside the component:
 * store.getters[`${ModulesTypes.FAVORITES}/${GettersTypes.ANY}`]
 * Need to use module folder name when calling getter.
 * Use the ModulesTypes from '@/store'
 */
export enum GettersTypes {}

/**
 * Getters
 * Define Getters to Favorites Module
 */
export const getters: any = {}

export default getters
