/**
 * Mapping of actions names to be used within components.
 * @example
 *
 * inside the component:
 * store.dispatch(`${ModulesTypes.FAVORITES}/${ActionsTypes.ANY}`).then().catch()
 * Need to use module folder name when calling getter.
 * Use the ModulesTypes from '@/store'
 */
export enum ActionsTypes {}

/**
 * Actions
 * Define Actions to Favorites Module
 */
export const actions = {}

export default actions
