/**
 * Storage Service
 * @description: Service to consume the local storage
 * @version: 1.0.0
 */
class StorageService {
  private readonly localStorageSupported: boolean

  constructor() {
    this.localStorageSupported =
      typeof window['localStorage'] != 'undefined' &&
      window['localStorage'] != null
  }

  /**
   * Adding item to local storage
   * @param key
   * @param item
   */
  add(key: string, item: string) {
    if (this.localStorageSupported) {
      localStorage.setItem(key, item)
    }
  }

  /**
   * Searching for item in local storage
   * @param key
   * @param item
   */
  get(key: string): string | null {
    if (this.localStorageSupported) {
      const item = localStorage.getItem(key)
      return item
    } else {
      return null
    }
  }

  /**
   * Removing item from local storage
   * @param key
   * @param item
   */
  remove(key: string) {
    if (this.localStorageSupported) {
      localStorage.removeItem(key)
    }
  }
}

export enum LocalStorageTypes {
  FAVORITES = 'lazlo_favorites',
  CART = 'lazlo_cart',
  ORDER = 'lazlo_order',
  QRCODE = 'lazlo_qrcode',
  CONSUMER_LICENSE_CODE = 'consumerLicenseCode',
  THEME = 'lazlo_theme',
}

export default new StorageService()
