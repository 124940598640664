import { State as StateChannels } from '@/store/modules/channels/state'

/**
 * Mapping of mutation names to be used within components.
 * @example
 *
 * inside the mutation:
 * [MutationTypes.SET_CHANNELS]: () => { return }
 *
 * inside the component:
 * store.commit(`${ModulesTypes.ECOMMERCE}/${MutationTypes.SET_CHANNELS}`, payload)
 * Need to use module folder name when calling commit.
 * Use the ModulesTypes from '@/store'
 */
export enum MutationTypes {
  SET_CHANNELS = 'SET_CHANNELS',
  SET_LOADING = 'SET_LOADING',
  SET_QUICKPICK_SELECTED = 'SET_QUICKPICK_SELECTED',
  SET_AMOUNT_OF_GAMES_SELECTED = 'SET_AMOUNT_OF_GAMES_SELECTED',
  SET_SELECTED_NUMBERS = 'SET_SELECTED_NUMBERS',
  SET_SELECTED_NUMBER = 'SET_SELECTED_NUMBER',
  SET_AMOUNT_SELECTED = 'SET_AMOUNT_SELECTED',
  RESET_CHANNEL = 'RESET_CHANNEL',
}

/**
 * Mutations
 * Mapping of mutations
 */
export type Mutations<S = StateChannels> = {
  [MutationTypes.SET_CHANNELS](state: S, payload: any): void
  [MutationTypes.SET_LOADING](state: S, payload: Boolean): void
  [MutationTypes.SET_QUICKPICK_SELECTED](state: S, payload: any): void
  [MutationTypes.SET_AMOUNT_OF_GAMES_SELECTED](state: S, payload: any): void
  [MutationTypes.SET_SELECTED_NUMBERS](state: S, payload: any): void
  [MutationTypes.SET_SELECTED_NUMBER](state: S, payload: any): void
  [MutationTypes.SET_AMOUNT_SELECTED](state: S, payload: any): void
  [MutationTypes.RESET_CHANNEL](state: S, payload: any): void
}

/**
 * Function to get the current channel
 * @param state
 * @param payload
 */
function getCurrentChannel(
  state: { channels: any[] },
  payload: { channelGroupId: string | string[]; channelId: string | string[] }
) {
  const currentGroupChannel = state.channels.find(
    (channelGroup: { id: string | string[] }) => {
      return channelGroup.id === payload.channelGroupId
    }
  )
  return currentGroupChannel.channels.find(
    (channel: { id: string | string[] }) => {
      return channel.id === payload.channelId
    }
  )
}

/**
 * Mutations
 * Define Mutations to Channels Module
 */
const mutations: any = {
  [MutationTypes.SET_CHANNELS](state: StateChannels | any, payload: any) {
    state.channels = payload
  },
  [MutationTypes.SET_LOADING](state: StateChannels | any, payload: Boolean) {
    state.loading = payload
  },
  [MutationTypes.SET_QUICKPICK_SELECTED](
    state: StateChannels | any,
    payload: any
  ) {
    const currentChannel = getCurrentChannel(state, payload)
    currentChannel.promotionRule.promotionProducts[0].demoOptionsSelected.isQuickPickSelected =
      payload.isQuickPickSelected
    currentChannel.promotionRule.promotionProducts[0].demoOptionsSelected.amountOfGames = 1
    if (payload.isQuickPickSelected) {
      currentChannel.promotionRule.promotionProducts[0].demoOptionsSelected.selectedNumbers =
        []
    }
  },
  [MutationTypes.SET_AMOUNT_OF_GAMES_SELECTED](
    state: StateChannels | any,
    payload: any
  ) {
    const currentChannel = getCurrentChannel(state, payload)
    currentChannel.promotionRule.promotionProducts[0].demoOptionsSelected.selectedNumbers =
      currentChannel.promotionRule.promotionProducts[0].demoOptionsSelected.selectedNumbers.slice(
        0,
        payload.amountOfGames
      )
    currentChannel.promotionRule.promotionProducts[0].demoOptionsSelected.amountOfGames =
      payload.amountOfGames
  },
  [MutationTypes.SET_SELECTED_NUMBERS](
    state: StateChannels | any,
    payload: any
  ) {
    const currentChannel = getCurrentChannel(state, payload)
    currentChannel.promotionRule.promotionProducts[0].demoOptionsSelected.selectedNumbers =
      payload.selectedNumbers
  },
  [MutationTypes.SET_SELECTED_NUMBER](
    state: StateChannels | any,
    payload: any
  ) {
    const currentChannel = getCurrentChannel(state, payload)
    currentChannel.promotionRule.promotionProducts[0].demoOptionsSelected.selectedNumbers[
      payload.selectedNumbersIndex
    ][payload.selectedBallIndex] = payload.selectedBall
  },
  [MutationTypes.SET_AMOUNT_SELECTED](
    state: StateChannels | any,
    payload: any
  ) {
    const currentChannel = getCurrentChannel(state, payload)
    currentChannel.promotionRule.promotionProducts[0].demoOptionsSelected.amountId =
      payload.amountId
    currentChannel.promotionRule.promotionProducts[0].demoOptionsSelected.amountCost =
      payload.amountCost
  },
  [MutationTypes.RESET_CHANNEL](state: StateChannels | any, payload: any) {
    const currentChannel = getCurrentChannel(state, payload)
    currentChannel.promotionRule.promotionProducts[0].demoOptionsSelected = {
      isQuickPickSelected: false,
      amountId: '',
      amountCost: 0,
      amountOfGames: 1,
      selectedNumbers: [],
    }
  },
}

export default mutations
