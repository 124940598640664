/**
 * A simple box component
 * @name 'box-l'
 * @version 1.0.0
 */
import { defineComponent, h } from 'vue';
export default defineComponent({
    name: 'box-l',
    setup(_props, { slots }) {
        return () => h('div', { class: ['box-l'] }, slots);
    },
});
