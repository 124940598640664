import http from '@/http-common'
import environment from '@/helpers/filters/environments'

/**
 * Products Service
 * @description: Service to consume the products API.
 * @version: 1.0.0
 */
class ProductsService {
  /**
   * Route Get ALl
   * Get the entire list of products within the channel.
   */
  getAll(): Promise<any> {
    return http.get(environment.apis.shopping.endpoints.products)
  }
}

export default new ProductsService()
