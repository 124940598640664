import { Game } from '../game/state'

export type Cart = {
  channelGroupId: string
  games: Game[]
}

/**
 * State
 * Object mapping to state type
 */
export type CartState = {
  cart: Cart[]
}

/**
 * State
 * Set State to Cart Module
 */
const state: CartState = {
  cart: [],
}

export default state
