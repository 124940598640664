import environment from '@/helpers/filters/environments'
import http from '@/http-common'
import { GameState } from '@/store/modules/game/state'
import StorageService, { LocalStorageTypes } from '@/services/StorageService'
import { AxiosResponseHeaders } from 'axios'

class CartService {
  submitCartAndGenerateCode(payload: GameState['currentGame'][]) {
    const headers: AxiosResponseHeaders = {
      'lazlo-consumerlicensecode':
        StorageService.get(LocalStorageTypes.CONSUMER_LICENSE_CODE) || '',
    }
    return http.post(
      environment.apis.shopping.endpoints.shoppingCart,
      {
        data: JSON.parse(JSON.stringify(payload)),
      },
      {
        headers,
      }
    )
  }
}

export default new CartService()
