import routes from 'vue-auto-routing'
import { createRouter, createWebHistory } from 'vue-router'
import { createRouterLayout } from 'vue-router-layout'

const RouterLayout = createRouterLayout((layout: string) => {
  // Resolves a layout component with layout type string.
  return import('@/layouts/' + layout + '.vue')
})

const normalizeRoute = (routeName: string): string => {
  return routeName.replace('-', '/_')
}

const routesWithLayouts = routes.map((route) => {
  route.component = RouterLayout
  const name = /\W|_/.test(route.name) ? normalizeRoute(route.name) : route.name

  route.children = [
    {
      path: route.path,
      component: () => import('@/views/' + name + '.vue'),
    },
  ]

  return route
})

const router = createRouter({
  history: createWebHistory(),
  routes: routesWithLayouts,
})

export default router
