import environment from '@/helpers/filters/environments'
import http from '@/http-common'

/**
 * Channel Groups Service
 * @description: Service to consume the channel groups API.
 * @version: 1.0.0
 */
class ChannelGroupsService {
  /**
   * Route Get ALl
   * Get the entire list of channel groups.
   */
  getAll(): Promise<any> {
    return http.get(environment.apis.shopping.endpoints.channelGroups)
  }
}

export default new ChannelGroupsService()
