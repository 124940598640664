/**
 * A component that can create a
 * horizontally centered column
 * @name 'cluster-l'
 * @version 1.0.0
 */
import { defineComponent, h } from 'vue';
export default defineComponent({
    name: 'cluster-l',
    setup(_props, { slots }) {
        return () => h('div', { class: ['cluster-l'] }, slots);
    },
});
