import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_router_link = _resolveComponent("router-link");
    return (_openBlock(), _createBlock(_component_router_link, {
        to: $setup.props.url,
        class: _normalizeClass(['lz-button', $setup.checkColors, { 'disabled': $props.disabled }])
    }, {
        default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ]),
        _: 3
    }, 8, ["to", "class"]));
}
