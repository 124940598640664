import { createApp } from 'vue'
import App from './App.vue'
import router from './routes/router'

import { svgSpritePlugin } from 'vue-svg-sprite'
// import { makeServer } from './_mockdata/server'

import mitt from 'mitt' // Import mitt
const emitter = mitt()

// Default CSS
import './assets/stylus/reset.styl'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'
/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
/* import specific icons */
import { faShoppingCart, faBars } from '@fortawesome/free-solid-svg-icons'
/* add icons to the library */
library.add(faShoppingCart, faBars)

createApp(App)
  .component('font-awesome-icon', FontAwesomeIcon)
  .use(router)
  .use(svgSpritePlugin, {
    url: require('@/assets/svg/sprite.svg'),
  })
  .provide('emitter', emitter)
  .mount('#app')
