import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_router_view = _resolveComponent("router-view");
    const _component_lz_notification = _resolveComponent("lz-notification");
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        _createElementVNode("div", null, [
            _createVNode(_component_router_view)
        ]),
        _createVNode(_component_lz_notification)
    ], 64));
}
